import { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";

import wallet from "../../assets/images/wallet.png";
import walletGoogle from "../../assets/images/g-wallet.png";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { sndSMSOrEmail } from "../../reducers/sendSMSOrEmail";
import { appleWalletAPI } from "../../reducers/appleWallet";
import { setSMSOrEmailResponse } from "../../reducers/sendSMSOrEmail";
import "react-toastify/dist/ReactToastify.css";
import { isIOS, isAndroid } from "react-device-detect";
import editIcon from "./../../assets/images/RxLink assets/new assets/edit pen.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SendSMSOrEmailModalTab } from "./Modals/SendSMSorEmailModal";
import { httpClientJava } from "../../environment";
import {
  membersAssign,
  setHippoCardInfo,
  createRxSenseCard,
  setRxSenseCardInfo,
  setConnectiveCards,
  createConnectiveRxCards,
} from "../../reducers/assignMembers";
import { getCrxBestPrice } from "../../utilities/GetPrice";
import { usernameValidation } from "../Fields/Username";

import { successToast, errorToast } from "../toast/toast";
import { searchPharmacyLogo } from "../PharmacyLogo";
import { getCardDetail, getCard } from "../../utilities/cardUtils";
import { getDomainName } from "../../utilities/useGetDomain";
import FAQ from "../FAQ";
import LoadingComponent from "../LoadingComponent";
import ariaAnnounce from "../../utilities/useAnnounceText";
import { useGetUser } from "../../utilities/useGetUser";
import { saveCareCardInfo, setSaveCareCardInfo } from "../../reducers/careCardInfo";
import { StringToHTML } from "../../utilities/StringToHtml";

export default (props) => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isEmailValue, setEmailValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const dispatch = useDispatch();
  const [isEmail, setIsEmail] = useState(false);
  const [isSMS, setIsSMS] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingHippoCard, setLoadingHippoCard] = useState(false);
  const [loadingRxCard, setLoadingRxCard] = useState(false);
  const [loadingConnectiveCard, setLoadingConnectiveCard] = useState(false);

  const [isValidEmailModal, setIsValidEmailModal] = useState("12");

  const [isWalletURL, setWalletURL] = useState("");
  const [pharmacistPara, setPharmacistPara] = useState(false);
  const [showMoreInstructions, setShowMoreInstructions] = useState(false);
  const [isEmailOrSMSSuccess, setIsEmailOrSMSSuccess] = useState(false);
  const [termsSMSorEmail, setTermsSMSorEmail] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [walletList, setWalletList] = useState([]);
  var numberPattern = /\d+/g;

  const state = props?.objParam;
  const originalFilter = props.originalFilter;

  const pharmacyState = JSON.parse(sessionStorage.getItem("selectMapValues"));

  const rxSenseCard = useSelector(
    (state) => state.membersAssignAPI.rxSenseCardInfo
  );
  const memberInfo = useSelector(
    (state) => state.membersAssignAPI.hippoCardInfo
  );
  const connectiveCards = useSelector(
    (state) => state.membersAssignAPI.connectiveCards
  );

  const careCards = useSelector( (state) => state?.careCardDetails?.careCardSave);

  const setMemberAssign = props.setMemberAssign;

  const { healthSystem, pid } = getDomainName();

  const userDetail = useGetUser();

  useEffect(() => {
    if (props.show) {
      const membersAssignData = JSON.parse(
        localStorage.getItem("membersAssign")
      );
      if (membersAssignData) {
        setMemberAssign(membersAssignData);
        dispatch(setHippoCardInfo(membersAssignData));
        return;
      }
      let body = {
        appUserSeqNo: userDetail?.appUserSeqNo,
      };
      setLoadingHippoCard(true);
      dispatch(membersAssign(body))
        .unwrap()
        .then((response) => {
          setLoadingHippoCard(false);
          setMemberAssign(JSON.parse(response?.data));
          dispatch(setHippoCardInfo(JSON.parse(response?.data)));
          localStorage.setItem("membersAssign", response?.data);
        })
        .catch((e) => {
          setLoadingHippoCard(false);
        });
    }
  }, [props.show]);

  useEffect(() => {
    if (props.show) {
      const rxSenseCardData = JSON.parse(localStorage.getItem("rxSenseCard"));
      if (rxSenseCardData) {
        dispatch(setRxSenseCardInfo(rxSenseCardData));
        return;
      }
      let body = {
        appUserSeqNo: userDetail?.appUserSeqNo,
      };
      setLoadingRxCard(true);
      dispatch(createRxSenseCard(body))
        .unwrap()
        .then((response) => {
          if (response?.data) {
            dispatch(setRxSenseCardInfo(response?.data));
            localStorage.setItem("rxSenseCard", JSON.stringify(response?.data));
          } else {
            dispatch(
              setRxSenseCardInfo({
                bin: "",
                pcn: "",
                memberId: "",
                groupId: "",
              })
            );
          }
          setLoadingRxCard(false);
        })
        .catch((e) => {
          setLoadingRxCard(false);
        });
    }
  }, [props.show]);

  const getConnectiveCardDrugs = (drugs) => {
    let filteredConnectiveDrugs = drugs.filter(
      (dr) => dr?.selectedDrug?.connectiveRxOffer?.hasCard == 1
    );
    return filteredConnectiveDrugs || [];
  };

  useEffect(() => {
    if (props.show) {
      let connectiveDrugs = getConnectiveCardDrugs(state.medDrugData);
      if (connectiveDrugs.length > 0) {
        console.table(connectiveDrugs);
        let body = [];
        connectiveDrugs.map((it) => {
          body.push({
            appUserSeqNo: userDetail?.appUserSeqNo,
            drugNdc: it?.selectedDrug?.connectiveRxOffer?.drugNdc,
            crxNetPriceSubSeqNo:
              it?.selectedDrug?.connectiveRxOffer?.crxNetPriceSubSeqNo,
            dob: userDetail?.dob,
            gender: userDetail?.gender,
            patientSeqNo: userDetail?.patientSeqNo,
            pharmacySeqNo: pharmacyState?.pharmacySeqNo || "1213",
            pharmacyState:
              pharmacyState?.state ||
              props?.ownPosition?.responseZIP?.region_code ||
              "",
            zipcode:
              pharmacyState?.zipCode || props?.ownPosition?.postalCode || "",
            campaignEndDate:
              it?.selectedDrug?.connectiveRxOffer?.campaignEndDate,
          });
        });
        setLoadingConnectiveCard(true);
        dispatch(
          createConnectiveRxCards({
            appUserSeqNo: userDetail?.appUserSeqNo,
            connectiveRxRequest: body,
          })
        )
          .unwrap()
          .then((response) => {
            if (response?.statusCode == 200) {
              let cards =
                response?.data?.length > 0 &&
                response?.data?.map((connective) => {
                  if (connective.statusCode == 200) {
                    return {
                      bin: connective?.programs?.[0]?.bin,
                      pcn: connective?.programs?.[0]?.pcn,
                      groupId: connective?.programs?.[0]?.group,
                      memberId: connective?.programs?.[0]?.memberId,
                      ndc: connective.drugNdc,
                    };
                  }
                });
              dispatch(setConnectiveCards(cards));
            } else {
              dispatch(setConnectiveCards([]));
            }
            setLoadingConnectiveCard(false);
          })
          .catch((e) => {
            setLoadingConnectiveCard(false);
          });
      }
    }
  }, [props.show]);

  useEffect(() => {
    
    if (props?.objParam?.medDrugData?.length > 0) {
      const getUniqueCareCards = (careCardArr, keys) => {
        return careCardArr.filter((item, index, self) =>
          index === self.findIndex((t) => (
            keys.every(key => t[key] === item[key])
          ))
        );
      };
      const uniqueCareCardArr = props?.objParam?.medDrugData?.filter((x) => { if (x?.isCareCard) { return x; } });
      //getUniqueCareCards(props?.objParam?.medDrugData?.filter((x) => { if (x?.isCareCard) { return x; } }), ['bin', 'pcn', 'groupId']);
      if (uniqueCareCardArr?.length > 0) {
        uniqueCareCardArr?.forEach((element, ind) => {
          if (element?.isCareCard) {
            let saveCareCardBody = {
              "appUserSeqNo": JSON.parse(localStorage.getItem("user"))?.appUserSeqNo,
              "ndc": element.selectedDrug.ndc,
              "price": element?.bestPrice,
              "estRetailPrice": element?.estRetailPrice,
              "quantity": element.selectedDrug.default_quantity,
              "pharmacyName": originalFilter.pharmacyName,
              "pharmacyNpi": originalFilter?.npi,
              "zipcode": originalFilter?.zipCode,
              "bin": element?.careCardInfo?.bin,
              "pcn": element?.careCardInfo?.pcn,
              "groupId": element?.careCardInfo?.groupId,
              "memberId": element?.careCardInfo?.memberId
            };
            if (saveCareCardBody?.appUserSeqNo) {
              dispatch(saveCareCardInfo(saveCareCardBody))
                .unwrap().then((response) => {
                  if (response?.data) {
                    dispatch(setSaveCareCardInfo(response?.data));
                  }
                })
            }
          }
        })
      }


    }
  }, [props?.objParam?.medDrugData]);

  const navigateToCouponSent = () => {
    setIsEmailOrSMSSuccess(true);
  };

  const termsHandler = (e) => {
    setTermsSMSorEmail(e.target.checked);
    if (e.target.checked) {
      setTermsError(false);
    }
  };

  const getServiceProvider = (med) => {
    if (med.hasInsurance) return "1";
    else if (med.rxSense) return "3";
    else return "2";
  };

  const checkSelected = (med, pharmacy) => {
    if (med.pharmacyName === pharmacy.pharmacyName) return 1;
    else return 0;
  };

  const checkBestPrice = (med) => {
    let chk = 1;
    let { id } = med.selectedDrug;
    state?.pharmacyDrugsInfo?.forEach((pharmacy, index) => {
      if (state?.drug?.pharmacyName !== pharmacy?.pharmacyName) {
        pharmacy.drugs.forEach((medicine, i) => {
          if (
            id == medicine.selectedDrug.id &&
            medicine.bestPrice < med.bestPrice
          ) {
            chk = 0;
          }
        });
      }
    });
    return chk;
  };

  useEffect(() => {
    if (state.pharmacyDrugsInfo && state.pharmacyDrugsInfo.length > 0) {
      const drugArr = [];
      state?.pharmacyDrugsInfo.forEach((pharmacy, index) => {
        pharmacy.drugs.forEach((med, i) => {
          let tempBody = {
            insuranceSeqNo: med.hasInsurance
              ? props?.insuranceDetail?.[0]?.insInfoSeqNo
              : null,
            varientId: med.selectedDrug.id,
            drugName: med.selectedDrug.name,
            form: med.selectedDrug.form,
            drugType: med.selectedDrug.type,
            dosage: med.selectedDrug.dosage,
            default_quantity: med.selectedDrug.default_quantity,
            ndc: med.selectedDrug.ndc,
            chain: pharmacy.chain,
            cost: med.bestPrice,
            estRetailPrice: med.estRetailPrice,
            pharmacyName: pharmacy.pharmacyName,
            zipcode: props.ownPosition.postalCode
              ? props.ownPosition.postalCode
              : "70005",
            serviceProvider: getServiceProvider(med),
            suggested: checkSelected(med, state.drug),
            bestPrice: checkBestPrice(med),
          };
          drugArr.push(tempBody);
        });
      });
      var userDetail = JSON.parse(localStorage.getItem("user") || []);
      httpClientJava
        .post("medication/savePatientPrices", {
          appUserSeqNo: userDetail.appUserSeqNo,
          patientPrices: drugArr,
        })
        .then((res) => {
          console.log({ res });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [state]);

  useEffect(() => {
    if (props?.objParam?.drug?.pharmacyName && rxSenseCard && memberInfo) {
      appleWallet();
    }
  }, [props?.objParam?.drug?.pharmacyName, rxSenseCard, memberInfo]);

  const validateEmail = (e) => {
    var email = e.target.value;
    setEmailValue(email);
    setEmail(e.target.value);
    if (validator.isEmail(email)) {
      setEmailError("");
      setIsValidEmail(true);
      setIsValidEmailModal(true);
    } else {
      setIsValidEmail(false);
      setEmailError("Enter valid email");
      setIsValidEmailModal(false);
    }
  };

  function processInput(input) {
    if (typeof input === 'string' && input.startsWith('[') && input.endsWith(']')) {
      // Remove the brackets
      let trimmedInput = input.slice(1, -1);

      // Split the string by comma and trim each element
      let arrayOfStrings = trimmedInput.split(',').map(item => item.trim());

      return arrayOfStrings;
    } else {
      // Assume the input is already in the correct format
      return input;
    }
  }

  const sendSMS = (data) => {
    // const { lat, lng, postalCode } = props.ownPosition;
    if (termsSMSorEmail) {
      setTermsError(false);
    } else {
      setTermsError(true);
      return;
    }
    if (data) {
      setLoading(true);

      let body = {
        sendSMS: true,
        mobileNo: data?.username.match(numberPattern).join(""),
        appUserSeqNo: userDetail.appUserSeqNo,
        memberInfo: memberInfo ? memberInfo : null,
        pid: (userDetail?.pid != null && userDetail?.pid != undefined && userDetail?.pid != "") ? userDetail?.pid : pid,
        pharmacyName: props?.objParam?.drug?.pharmacyName,
        longitude: pharmacyState?.lng || props?.ownPosition?.lng,
        latitude: pharmacyState?.lat || props?.ownPosition?.lat,
        state: pharmacyState?.state,
        address: originalFilter ? originalFilter?.address : "",
        walletHash: userDetail?.walletHash,
        drugs: props?.objParam?.medDrugData?.map((element, index) => {
          if (element?.chain || element?.bestPrice > 0 || element?.hasInsurance) {
            let med = props?.drugPrices[index];
            let names = processInput(element?.selectedDrug?.names);

            const getName = () => {
              if (names.length > 1) {
                return (
                  names[1] +
                  ` (${state?.medDrugData[index]?.selectedDrug?.name})`
                );
              }
              else if (names.length == 1) {
                return state?.medDrugData[index]?.selectedDrug?.name
              }
            }
     
            return {
              name: getName(),
              dosage: element?.selectedDrug?.dosage,
              form: element?.selectedDrug?.form,
              default_quantity: element?.selectedDrug?.default_quantity,
              price:
                med?.selectedDrug?.connectiveRxOffer?.hasCard == 1
                  ? med?.selectedDrug?.connectiveRxOffer?.price
                  : med?.bestPrice,
              cardInfo: getCard(med, rxSenseCard, memberInfo, connectiveCards, careCards),
            };
          }

        }).filter(x => x != undefined),
      };
      dispatch(sndSMSOrEmail(body))
        .unwrap()
        .then((response) => {
          if (response?.status === "OK") {
            setIsSMS(false);
            navigateToCouponSent();
            setTermsSMSorEmail(false);
            successToast(response?.message);
            dispatch(setSMSOrEmailResponse(response?.data?.memberInfo));
            localStorage.setItem(
              "memberInfo",
              JSON.stringify(response?.data?.memberInfo)
            );
            setWalletURL(response?.data?.walletUrl);
          } 
          else if(response?.status === "BANDWIDTH_LIMIT_EXCEEDED"){
            errorToast(<StringToHTML htmlString={"The coupon was already sent to this number. Please wait 10 minutes and if the coupon is still not received, try to use the email or mobile wallet option or email us at: <a href='mailto:support@rxlink.com'>support@rxlink.com</a>"} />);
          }
          else {
            errorToast(response?.message);
          }
        })
        .catch((e) => { })
        .finally((e) => {
          setLoading(false);
        });
    }
  };

  const sendEmail = (flag) => {
    if (!isEmailValue) {
      setEmailError("Email is required");
      return;
    }

    if (!isValidEmail) {
      setEmailError("Enter valid email");
      return;
    }
    if (termsSMSorEmail) {
      setTermsError(false);
    } else {
      setTermsError(true);
      return;
    }

    if (flag) {
      setLoading(true);
      let body = {
        sendSMS: false,
        appUserSeqNo: userDetail?.appUserSeqNo,
        email: email,
        pharmacyName: props?.objParam?.drug?.pharmacyName,
        price: "$" + getCrxBestPrice(props?.objParam?.drug),
        state: pharmacyState?.state,
        address: originalFilter ? originalFilter?.address : "",
        walletHash: userDetail?.walletHash,
        pid: (userDetail?.pid != null && userDetail?.pid != undefined && userDetail?.pid != "") ? userDetail?.pid : pid,
        drugs: props?.objParam?.medDrugData?.map((element, index) => {
          if (element?.chain || element?.bestPrice > 0 || element?.hasInsurance) {
            let med = props?.drugPrices[index];
            let names = processInput(element?.selectedDrug?.names);

            const getName = () => {
              if (names.length > 1) {
                return (
                  names[1] +
                  ` (${state?.medDrugData[index]?.selectedDrug?.name})`
                );
              }
              else if (names.length == 1) {
                return state?.medDrugData[index]?.selectedDrug?.name
              }
            }
            return {
              name: getName(),
              dosage: element?.selectedDrug?.dosage,
              form: element?.selectedDrug?.form,
              default_quantity: element?.selectedDrug?.default_quantity,
              price:
                med?.selectedDrug?.connectiveRxOffer?.hasCard == 1
                  ? med?.selectedDrug?.connectiveRxOffer?.price
                  : med?.bestPrice,
              cardInfo: getCard(med, rxSenseCard, memberInfo, connectiveCards, careCards),
            };
          }

        }).filter(x => x != undefined),
        longitude: pharmacyState?.lng || props?.ownPosition?.lng,
        latitude: pharmacyState?.lat || props?.ownPosition?.lat,
      };
      dispatch(sndSMSOrEmail(body))
        .unwrap()
        .then((response) => {
          setLoading(false);

          if (response?.status === "OK") {
            setIsEmail(false);
            navigateToCouponSent();
            setTermsSMSorEmail(false);
            setEmailValue("");
            successToast(response?.message);
            dispatch(setSMSOrEmailResponse(response?.data?.memberInfo));
            localStorage.setItem(
              "memberInfo",
              JSON.stringify(response?.data?.memberInfo)
            );
          } else {
            setEmailValue("");
            errorToast(response?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };

  const appleWallet = () => {
    if (
      !props?.objParam?.drug?.pharmacyName &&
      !JSON.parse(localStorage.getItem("user")).walletHash
    ) {
      return;
    }
    else {
      setLoading(true);
      let body = {
        appUserSeqNo: JSON.parse(localStorage.getItem("user")).appUserSeqNo,
        pharmacyName: props?.objParam?.drug?.pharmacyName,
        pid: (userDetail?.pid != null && userDetail?.pid != undefined && userDetail?.pid != "") ? userDetail?.pid : pid,
        price: "$" + getCrxBestPrice(props?.objParam?.drug),
        drugs: props?.objParam?.medDrugData
          ?.map((element, index) => {
            let med = props?.drugPrices[index];
            if (!med.hasInsurance && med?.bestPrice > 0)
              return {
                name: element?.selectedDrug?.search_name,
                dosage: element?.selectedDrug?.dosage,
                form: element?.selectedDrug?.form,
                default_quantity: element?.selectedDrug?.default_quantity,
                price:
                  med?.selectedDrug?.connectiveRxOffer?.hasCard == 1
                    ? med?.selectedDrug?.connectiveRxOffer?.price
                    : med?.bestPrice,
                cardInfo: getCard(
                  med,
                  rxSenseCard,
                  memberInfo,
                  connectiveCards,
                  careCards
                ),
              };
          })
          .filter(Boolean),
        memberInfo: {},
        longitude: pharmacyState?.lng || props?.ownPosition?.lng,
        latitude: pharmacyState?.lat || props?.ownPosition?.lat,
        state: pharmacyState?.state,
        address: originalFilter ? originalFilter?.address : "",
        walletHash: JSON.parse(localStorage.getItem("user")).walletHash,
      };
      dispatch(appleWalletAPI(body))
        .unwrap()
        .then((response) => {
          setLoading(false);

          if (response?.status === "OK") {
            dispatch(setSMSOrEmailResponse(response?.data?.memberInfo));
            localStorage.setItem(
              "memberInfo",
              JSON.stringify(response?.data?.memberInfo)
            );
            setWalletURL(response?.data?.walletUrl);
          } else {
            errorToast(response?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
        });

      // })
    }
  };

  const validationSchema = Yup.object().shape({
    username: usernameValidation,
  });

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema,

    onSubmit: (data) => {
      sendSMS(data);
    },
  });

  const openSMSpopup = (e) => {
    formik.resetForm();
    setIsSMS(e);
    ariaAnnounce("Send a coupon via SMS");
  };

  const closeSMSOrEmailPopup = (e) => {
    setTermsError(false);
    setTermsSMSorEmail(false);
    setIsEmailOrSMSSuccess(e);
    formik.setTouched({}, false);
    setIsSMS(e);
    setIsEmail(e);
  };

  const openEmailPopup = (e) => {
    setIsEmail(e);
    ariaAnnounce("Send a coupon via e-Mail");
    setEmailError("");
  };

  const openMoreInformation = () => {
    setShowMoreInstructions(true);
    setPharmacistPara(false);
  };

  console.log({ props });

  return (
    <>
      <Modal
        show={props.show}
        centered
        keyboard={false}
        dialogClassName="modal-custom-className"
        aria-labelledby="example-custom-modal-styling-title"
        id="example-custom-modal-styling-title"
      >
        <Modal.Body id="example-modal-sizes-title-lg">
          {loading ||
            loadingHippoCard ||
            loadingRxCard ||
            loadingConnectiveCard ? (
            <LoadingComponent />
          ) : (
            <>
              {!pharmacistPara &&
                !showMoreInstructions &&
                rxSenseCard &&
                memberInfo &&
                connectiveCards && (
                  <section className="popupCouponCard">
                    <section className="tabCoupon ">
                      <div className="">
                        <div className="row coupon_main">
                          <div className="col-sm-12 col-md-12 col-lg-12 ">
                            <div className="medications_inner medications_inner_coupon dlr-Sup  medications_inner_imhere ">
                              <div className="row">
                                <div className="col-7 col-sm-7 col-md-9 col-lg-9 ">
                                  <label
                                    className=""
                                    tabIndex={0}
                                    aria-label="TOTAL OUT OF POCKET"
                                  >
                                    TOTAL OUT OF POCKET
                                  </label>
                                  <div className="med-Flex">
                                    <h1
                                      className="webView div-hidden"
                                      tabIndex={0}
                                      aria-label={
                                        "$" +
                                        getCrxBestPrice(props?.objParam?.drug)
                                      }
                                    >
                                      <sup>
                                        <b>$&nbsp;</b>
                                      </sup>
                                      {                  
                                        getCrxBestPrice(props?.objParam?.drug)
                                      }{" "}
                                    </h1>
                                    <h2
                                      className="tabView"
                                      tabIndex={0}
                                      aria-label={
                                        "$" +
                                        getCrxBestPrice(props?.objParam?.drug)
                                      }
                                    >
                                      <sup>
                                        <b>$&nbsp;</b>
                                      </sup>
                                      {
                                        getCrxBestPrice(props?.objParam?.drug)
                                      }{" "}
                                    </h2>
                                    <h3
                                      className="mobileView"
                                      style={{ display: "flex" }}
                                      tabIndex={0}
                                      aria-label={
                                        "$" +
                                        getCrxBestPrice(props?.objParam?.drug)
                                      }
                                    >
                                      <sup
                                        style={{ paddingTop: 10, fontSize: 11 }}
                                      >
                                        <b>$&nbsp;</b>
                                      </sup>
                                      {
                                        getCrxBestPrice(props?.objParam?.drug)
                                      }{" "}
                                    </h3>
                                    {props?.objParam?.savingPercentage > 0 && (
                                      <p
                                        className="font13 flex-end"
                                        tabIndex={0}
                                        aria-label={
                                          Math.round(
                                            props?.objParam?.savingPercentage
                                          ) + "% off"
                                        }
                                      >
                                        {Math.round(
                                          props?.objParam?.savingPercentage
                                        )}
                                        %&nbsp;off{" "}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-5 col-sm-5 col-md-3 col-lg-3 autoMargin">
                                  <button
                                    className="btn_default"
                                    onClick={props?.close}
                                  >
                                    Hide&nbsp;coupons
                                  </button>
                                </div>
                              </div>
                              <div className="row hrDiv g-0">
                                <div className="col-sm-12 col-md-12 col-lg-5">
                                  <label className="">PHARMACY</label>
                                </div>
                              </div>
                              <div className="row ">
                                <div className="col-sm-12 col-md-12 col-lg-5">
                                  <div className="review-coupon  m-0">
                                    <div className="row ">
                                      <div className="col-3 col-sm-3 col-md-3 col-lg-3 autoMargin">
                                        <div className="pharmacyImg_div">
                                          <img
                                            src={searchPharmacyLogo(
                                              props.objParam?.drug
                                                ?.pharmacyName,
                                              props?.objParam?.drug?.logo?.small,
                                              props?.objParam?.drug?.chain?.toLowerCase()
                                            )}
                                            className="img-fluid "
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div className="col-7 col-sm-7 col-md-7 col-lg-7 autoMargin">
                                        <div className="">
                                          <p className="shopName">
                                            {" "}
                                            {props.objParam?.drug?.pharmacyName}
                                          </p>
                                        </div>
                                      </div>                                   
                                      <div className="col-2 col-sm-2 col-md-2 col-lg-2 autoMargin text-end">
                                        <a
                                          href="javascript:void(0)"
                                          onClick={props.pencilAction}
                                        >
                                          <img
                                            className="pointer"
                                            src={editIcon}
                                            alt="Change pharmacy"
                                          />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-5">
                                  <h4 className="mt-2 mb-2 webView font-weig-4">
                                    Save your discounts to keep them handy
                                  </h4>
                                </div>
                                <div className="mobileView mt-2">
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                                      <h4 className="row  mb-2  font-weig-4 ">
                                        <b className="font_14_force p-0">
                                          {" "}
                                          Save and keep your coupons handy
                                        </b>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-7 alignSelfEnd">
                                  <div className="row mt-1">
                                    <div className="col-xs-12 col-3 col-sm-4 col-md-4 col-lg-3 autoMargin ">
                                      <button
                                        className="btn_success"
                                        type="button"
                                        onClick={() => openSMSpopup(true)}
                                      >
                                        SMS
                                      </button>
                                    </div>
                                    <div className="col-xs-12 col-3 col-sm-4 col-md-4 col-lg-3 autoMargin ">
                                      <button
                                        className="btn_success"
                                        type="button"
                                        onClick={() => openEmailPopup(true)}
                                      >
                                        Email
                                      </button>
                                    </div>
                                    <div
                                      className="col-xs-12 col-6 col-sm-4 col-md-4 col-lg-6"
                                      style={{ alignSelf: "flex-end" }}
                                    >
                                      <div>
                                        {isIOS && (
                                          <>
                                            <a
                                              href={isWalletURL}
                                              className="mobileView"
                                            >
                                              <img
                                                src={wallet}
                                                className="img-fluid"
                                                style={{ minHeight: 45 }}
                                                alt=""
                                              />
                                            </a>
                                          </>
                                        )}

                                        {isAndroid && (
                                          <>
                                            <a href={isWalletURL}>
                                              <img
                                                src={walletGoogle}
                                                className="img-fluid"
                                                style={{ minHeight: 45 }}
                                                alt=""
                                              />
                                            </a>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="hrDiv mobileView"></div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  {props.windowWidth < 992 && (
                                    <div
                                      className="offer-section"
                                      style={{
                                        marginTop:
                                          props.windowWidth < 768 ? -4 : 12,
                                        marginBottom:
                                          props.windowWidth < 768 ? 0 : -18,
                                      }}
                                    >
                                      {props.cheaperOptionAvailable ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <i
                                            class="fa-solid fa-circle-exclamation"
                                            style={{ fontSize: 21 }}
                                          ></i>
                                          <span style={{ marginLeft: 15 }}>
                                            Your price may be lower at{" "}
                                            <a
                                              href="javascript:void(0)"
                                              onClick={props.pencilAction}
                                            >
                                              a different pharmacy.
                                            </a>
                                          </span>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <i
                                            class="fa-solid fa-tag"
                                            style={{
                                              color: "#40DFB9",
                                              fontSize: 24,
                                            }}
                                          ></i>
                                          <span style={{ marginLeft: 15 }}>
                                            Congratulations! Your pharmacy has
                                            the lowest total price for your
                                            drugs in your area!
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-12 col-lg-12 ">
                            <div className="medications_inner medications_inner_coupon  dlr-Sup">
                              <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                  <h4 className="coupon_link">
                                    Show the coupons and{" "}
                                    <a
                                      onClick={() => setPharmacistPara(true)}
                                      className="text-underline pharmacyINC"
                                    >
                                      pharmacy instructions
                                    </a>{" "}
                                    to your pharmacy staff.{" "}
                                    <a className="pharmacyINC text-underline"
                                      style={{ marginLeft: 2 }}
                                      href="javascript:void(0)"
                                      onClick={() => openMoreInformation()}
                                    >
                                      More Information on how to use your
                                      coupons.
                                    </a>
                                  </h4>
                                  <div className=" p-0">
                                    <div className=" p-0">
                                      <div className="">
                                        {props.drugPrices?.map((med, index) => {
                                          let exUrl = walletList.filter(
                                            (wallet) =>
                                              wallet.name ==
                                              med?.medicationAPIRes?.search_name
                                          );
                                          return (
                                            <>
                                              {(med?.chain != undefined || med?.bestPrice > 0 || med?.hasInsurance) && (
                                                <>
                                                  <span>
                                                    <h4
                                                      className="normal mt-3 mb-3 inline"
                                                      style={{ display: "inline" }}
                                                    >
                                                      {med?.hasInsurance
                                                        ? "Use with insurance"
                                                        : "With RxLink Offer"}
                                                    </h4>                                              
                                                  </span>
                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                                                    <div className="card_info_new ">
                                                      <div className="row">
                                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                                          <div className="card_info_new_left">
                                                            <h3 className="webView">
                                                              {
                                                                med?.selectedDrug?.name
                                                              }
                                                            </h3>
                                                            <h4 className="mobileView">
                                                              {
                                                                med?.selectedDrug?.name
                                                              }
                                                            </h4>
                                                            <p className="mt-2">
                                                              {med?.selectedDosage}
                                                              {med?.selectedDosage &&
                                                                ", "}
                                                              {med?.selectedQty}{" "}
                                                              {med?.selectedForm}
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                                          <div className="card_info_new_right">
                                                            {med?.selectedDrug
                                                              ?.connectiveRxOffer
                                                              ?.hasCard != 1 &&
                                                              (med?.bestPrice > 0 || med?.hasInsurance) ? (
                                                              <span>
                                                                <b>$&nbsp;</b>
                                                                <h3 className="mt-2 card_info__ d-inline">
                                                                  <sup className="sup"></sup>
                                                                  {med?.bestPrice?.toLocaleString(
                                                                    undefined,
                                                                    {
                                                                      minimumFractionDigits: 2,
                                                                      maximumFractionDigits: 2,
                                                                    }
                                                                  )}
                                                                </h3>
                                                              </span>
                                                            ) : med?.selectedDrug
                                                              ?.connectiveRxOffer
                                                              ?.hasCard == 1 ? (
                                                              <h3 className="mt-2 card_info__">
                                                                <b>$&nbsp;</b>
                                                                {parseFloat(
                                                                  med?.selectedDrug
                                                                    ?.connectiveRxOffer
                                                                    ?.price
                                                                )?.toLocaleString(
                                                                  undefined,
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )}
                                                              </h3>
                                                            ) : (
                                                              <h3 className="mt-2 card_info__">
                                                                ---
                                                              </h3>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <div className="text-center">
                                                            <span>&nbsp;</span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="car_info_bottom_new">
                                                      <div className="row">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <div className="couponTable">
                                                            <div className="row">
                                                              <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                                                                <div className="webView">
                                                                  <label>BIN</label>
                                                                  <h4 className="opacity_1">
                                                                    {getCardDetail(
                                                                      "BIN",
                                                                      med,
                                                                      rxSenseCard,
                                                                      memberInfo,
                                                                      connectiveCards
                                                                    )}
                                                                  </h4>
                                                                </div>
                                                                <div className="mobileView">
                                                                  <div className="row">
                                                                    <div className="col-sm-12">
                                                                      <label>
                                                                        BIN
                                                                      </label>
                                                                    </div>
                                                                  </div>
                                                                  <div className="row">
                                                                    <div className="col-sm-12">
                                                                      <label className="insrance_card_bottom_label_black opacity_1">
                                                                        {getCardDetail(
                                                                          "BIN",
                                                                          med,
                                                                          rxSenseCard,
                                                                          memberInfo,
                                                                          connectiveCards
                                                                        )}
                                                                      </label>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                                                                <div className="webView">
                                                                  <label>PCN</label>
                                                                  <h4 className="opacity_1">
                                                                    {" "}
                                                                    {getCardDetail(
                                                                      "PCN",
                                                                      med,
                                                                      rxSenseCard,
                                                                      memberInfo,
                                                                      connectiveCards
                                                                    )}
                                                                  </h4>
                                                                </div>
                                                                <div className="mobileView">
                                                                  <div className="row">
                                                                    <div className="col-sm-12">
                                                                      <label>
                                                                        PCN
                                                                      </label>
                                                                    </div>
                                                                  </div>
                                                                  <div className="row">
                                                                    <div className="col-sm-12">
                                                                      <label className="insrance_card_bottom_label_black opacity_1">
                                                                        {getCardDetail(
                                                                          "PCN",
                                                                          med,
                                                                          rxSenseCard,
                                                                          memberInfo,
                                                                          connectiveCards
                                                                        )}
                                                                      </label>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                                                                <div className="webView">
                                                                  <label>
                                                                    GROUP
                                                                  </label>
                                                                  <h4 className="opacity_1">
                                                                    {" "}
                                                                    {getCardDetail(
                                                                      "GROUP_ID",
                                                                      med,
                                                                      rxSenseCard,
                                                                      memberInfo,
                                                                      connectiveCards
                                                                    )}
                                                                  </h4>
                                                                </div>
                                                                <div className="mobileView">
                                                                  <div className="row">
                                                                    <div className="col-sm-12">
                                                                      <label>
                                                                        GROUP
                                                                      </label>
                                                                    </div>
                                                                  </div>
                                                                  <div className="row">
                                                                    <div className="col-sm-12">
                                                                      <label className="insrance_card_bottom_label_black opacity_1">
                                                                        {getCardDetail(
                                                                          "GROUP_ID",
                                                                          med,
                                                                          rxSenseCard,
                                                                          memberInfo,
                                                                          connectiveCards
                                                                        )}
                                                                      </label>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="col-6 col-sm-3 col-md-3 col-lg-3 ">
                                                                <div className="webView">
                                                                  <label>ID</label>
                                                                  <h4 className="opacity_1">
                                                                    {" "}
                                                                    {getCardDetail(
                                                                      "MEMBER_ID",
                                                                      med,
                                                                      rxSenseCard,
                                                                      memberInfo,
                                                                      connectiveCards
                                                                    )}
                                                                  </h4>
                                                                </div>
                                                                <div className="mobileView">
                                                                  <div className="row">
                                                                    <div className="col-sm-12">
                                                                      <label>
                                                                        ID
                                                                      </label>
                                                                    </div>
                                                                  </div>
                                                                  <div className="row">
                                                                    <div className="col-sm-12">
                                                                      <label className="insrance_card_bottom_label_black opacity_1">
                                                                        {getCardDetail(
                                                                          "MEMBER_ID",
                                                                          med,
                                                                          rxSenseCard,
                                                                          memberInfo,
                                                                          connectiveCards
                                                                        )}
                                                                      </label>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>

                                                </>
                                              )}

                                            </>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </section>
                )}
              {pharmacistPara && !showMoreInstructions && (
                <div className="row g-0" id="btn-row-back">
                  <>
                    <section>
                      <div className="row ">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="medicine_icon pharma_icon back_icon ps-3">
                            <a
                              className="txtDecoration"
                              href="javascript:void(0)"
                              onClick={() => setPharmacistPara(false)}
                            >
                              {/* <MotionIcon> */}
                              <i
                                className="fas fa-angle-left"
                                aria-label="Go Back"
                              ></i>
                              {/* </MotionIcon> */}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div
                            className="medication-heading instructionHeading"
                            role="alert"
                          >
                            <h2>Instructions for pharmacy staff</h2>
                          </div>
                        </div>
                      </div>
                      <motion.div
                        className="row  imhere_main"
                        initial={{ y: "-70vh", opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.5, type: "tween" }}
                      >
                        <div className="col-sm-12 col-md-12 col-lg-12 ">
                          <div className="medications_inner m-0 p-0">
                            <div className="row">
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="inst-ff">
                                  <p
                                    style={{
                                      paddingBottom: 10,
                                      fontSize: 16,
                                      color: "rgba(0, 0, 0, 0.7)",
                                    }}
                                  >
                                    {healthSystem} runs value-based care
                                    programs on certain populations of patients
                                    and appreciates you processing this savings
                                    card.  They will be monitoring the savings
                                    your pharmacy brings their members through
                                    these discounts. Thank you for your
                                    partnership.
                                  </p>
                                  <motion.div
                                    className=""
                                    initial={{ scale: 0.5 }}
                                    animate={{ scale: 1 }}
                                    transition={{
                                      duration: 0.5,
                                      delay: 0.3,
                                      type: "tween",
                                    }}
                                  >
                                    {props.drugPrices?.map((med, index) => {
                                      return (
                                        <>
                                          {(med?.chain != undefined || med?.bestPrice > 0 || med?.hasInsurance) && (
                                            <>
                                              <h4 className="weight600  mb-4 ">
                                                {index + 1}. For{" "}
                                                {med?.selectedLabel}
                                              </h4>
                                              <h4 className="normal mt-3 mb-3">
                                                {med?.hasInsurance
                                                  ? "Use with insurance"
                                                  : med?.description}
                                              </h4>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="card_info_new ">
                                                  <div className="row">
                                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                                      <div className="card_info_new_left">
                                                        <h3 className="webView">
                                                          {
                                                            med?.medicationAPIRes
                                                              ?.search_name
                                                          }
                                                        </h3>
                                                        <h4 className="mobileView">
                                                          {
                                                            med?.medicationAPIRes
                                                              ?.search_name
                                                          }
                                                        </h4>
                                                        <p className="mt-2">
                                                          {med?.selectedDosage}
                                                          {med?.selectedDosage &&
                                                            ", "}
                                                          {med?.selectedQty}{" "}
                                                          {med?.selectedForm}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                                      <div className="card_info_new_right">
                                                        {med?.selectedDrug
                                                          ?.connectiveRxOffer
                                                          ?.hasCard != 1 &&
                                                          (med?.bestPrice > 0 || med?.hasInsurance) ? (
                                                          <span>
                                                            <b>$&nbsp;</b>
                                                            <h3 className="mt-2 card_info__ d-inline">
                                                              <sup className="sup"></sup>
                                                              {med?.bestPrice?.toLocaleString(
                                                                undefined,
                                                                {
                                                                  minimumFractionDigits: 2,
                                                                  maximumFractionDigits: 2,
                                                                }
                                                              )}
                                                            </h3>
                                                          </span>
                                                        ) : med?.selectedDrug
                                                          ?.connectiveRxOffer
                                                          ?.hasCard == 1 ? (
                                                          <h3 className="mt-2 card_info__">
                                                            <b>$&nbsp;</b>
                                                            {parseFloat(
                                                              med?.selectedDrug
                                                                ?.connectiveRxOffer
                                                                ?.price
                                                            )?.toLocaleString(
                                                              undefined,
                                                              {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                              }
                                                            )}
                                                          </h3>
                                                        ) : (
                                                          <h3 className="mt-2 card_info__">
                                                            ---
                                                          </h3>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                      <div className="text-center">
                                                        <span>&nbsp;</span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="car_info_bottom_new">
                                                  <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                      <div className="couponTable">
                                                        <div className="row">
                                                          <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                                                            <div className="webView">
                                                              <label>BIN</label>
                                                              <h4 className="opacity_1">
                                                                {getCardDetail(
                                                                  "BIN",
                                                                  med,
                                                                  rxSenseCard,
                                                                  memberInfo,
                                                                  connectiveCards
                                                                )}
                                                              </h4>
                                                            </div>
                                                            <div className="mobileView">
                                                              <div className="row">
                                                                <div className="col-sm-12">
                                                                  <label>BIN</label>
                                                                </div>
                                                              </div>
                                                              <div className="row">
                                                                <div className="col-sm-12">
                                                                  <label className="insrance_card_bottom_label_black opacity_1">
                                                                    {getCardDetail(
                                                                      "BIN",
                                                                      med,
                                                                      rxSenseCard,
                                                                      memberInfo,
                                                                      connectiveCards
                                                                    )}
                                                                  </label>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                                                            <div className="webView">
                                                              <label>PCN</label>
                                                              <h4 className="opacity_1">
                                                                {" "}
                                                                {getCardDetail(
                                                                  "PCN",
                                                                  med,
                                                                  rxSenseCard,
                                                                  memberInfo,
                                                                  connectiveCards
                                                                )}
                                                              </h4>
                                                            </div>
                                                            <div className="mobileView">
                                                              <div className="row">
                                                                <div className="col-sm-12">
                                                                  <label>PCN</label>
                                                                </div>
                                                              </div>
                                                              <div className="row">
                                                                <div className="col-sm-12">
                                                                  <label className="insrance_card_bottom_label_black opacity_1">
                                                                    {getCardDetail(
                                                                      "PCN",
                                                                      med,
                                                                      rxSenseCard,
                                                                      memberInfo,
                                                                      connectiveCards
                                                                    )}
                                                                  </label>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                                                            <div className="webView">
                                                              <label>GROUP</label>
                                                              <h4 className="opacity_1">
                                                                {" "}
                                                                {getCardDetail(
                                                                  "GROUP_ID",
                                                                  med,
                                                                  rxSenseCard,
                                                                  memberInfo,
                                                                  connectiveCards
                                                                )}
                                                              </h4>
                                                            </div>
                                                            <div className="mobileView">
                                                              <div className="row">
                                                                <div className="col-sm-12">
                                                                  <label>
                                                                    GROUP
                                                                  </label>
                                                                </div>
                                                              </div>
                                                              <div className="row">
                                                                <div className="col-sm-12">
                                                                  <label className="insrance_card_bottom_label_black opacity_1">
                                                                    {getCardDetail(
                                                                      "GROUP_ID",
                                                                      med,
                                                                      rxSenseCard,
                                                                      memberInfo,
                                                                      connectiveCards
                                                                    )}
                                                                  </label>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="col-6 col-sm-3 col-md-3 col-lg-3 ">
                                                            <div className="webView">
                                                              <label>ID</label>
                                                              <h4 className="opacity_1">
                                                                {" "}
                                                                {getCardDetail(
                                                                  "MEMBER_ID",
                                                                  med,
                                                                  rxSenseCard,
                                                                  memberInfo,
                                                                  connectiveCards
                                                                )}
                                                              </h4>
                                                            </div>
                                                            <div className="mobileView">
                                                              <div className="row">
                                                                <div className="col-sm-12">
                                                                  <label>ID</label>
                                                                </div>
                                                              </div>
                                                              <div className="row">
                                                                <div className="col-sm-12">
                                                                  <label className="insrance_card_bottom_label_black opacity_1">
                                                                    {getCardDetail(
                                                                      "MEMBER_ID",
                                                                      med,
                                                                      rxSenseCard,
                                                                      memberInfo,
                                                                      connectiveCards
                                                                    )}
                                                                  </label>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}

                                        </>
                                      );
                                    })}
                                  </motion.div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    </section>
                  </>
                </div>
              )}
              {
                showMoreInstructions && !pharmacistPara && (
                  <div className="row g-0" id="btn-row-back">
                    <>
                      <section>
                        <div className="row ">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="medicine_icon pharma_icon back_icon ps-3">
                              <a
                                className="txtDecoration"
                                href="javascript:void(0)"
                                onClick={() => {
                                  setPharmacistPara(false);
                                  setShowMoreInstructions(false);
                                }}
                              >
                                <i
                                  className="fas fa-angle-left"
                                  aria-label="Go Back"
                                ></i>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="row medications_inner medications_inner_coupon  dlr-Sup">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <FAQ />
                          </div>
                        </div>                   
                      </section>
                    </>
                  </div>
                )
              }
              <SendSMSOrEmailModalTab
                isOpen={isSMS || isEmail || isEmailOrSMSSuccess}
                formik={formik}
                handleClose={() => closeSMSOrEmailPopup(false)}
                isSMS={isSMS}
                sendEmail={() => sendEmail(true)}
                sendSMS={formik.handleSubmit}
                validateEmail={validateEmail}
                emailError={emailError}
                hasSuccess={isEmailOrSMSSuccess}
                termsHandler={termsHandler}
                termsError={termsError}
                isValidEmailModal={isValidEmailModal}
                isEmail={isEmail}
              />
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
