export const customMedications = [
    {
        name: "Fasenra",
        form: "Prefilled Syringe",
        dosage: "1 ml",
        quantity: "1",
        default_quantity: "1",
        seoName: "Fasenra",
        ndc: "00310173030",
        package_size: 1,
        search_name: "Fasenra",
        discription: "",
        quantities: ["1"],
        type: "Brand",
        names: ["Fasenra"],
    },
    {
        name: "Xyrem",
        form: "Bottle",
        dosage: "180 mL",
        quantity: "1",
        default_quantity: "1",
        seoName: "Xyrem",
        ndc: "00054962857",
        package_size: 1,
        search_name: "Xyrem",
        discription: "",
        quantities: ["1"],
        type: "Brand",
        names: ["Xyrem"],
    }
]